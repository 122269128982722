import React from "react";

const App = () => {
  // Función para redirigir a WhatsApp
  const redirectToWhatsApp = () => {
    window.open("https://wa.me/573207578092", "_blank");
  };

  return (
    <div className="bg-black h-screen xl:bg-cover xl:bg-[url('/public/bghd.jpg')] flex justify-center items-center relative">
      <div className="flex flex-col h-[90%] w-[90%] items-center">
        <img className="relative xl:hidden rounded-lg border-[1px] " src="./bghd.jpg" alt="" />
        <div className="flex bg-black bg-opacity-80 rounded-lg lg:h-[90%] mt-8 w-[93%] justify-center items-center">
          <div className="text-white text-center">
            <h1 className="text-4xl sm:text-6xl font-bold mb-4 sm:mb-16">
              Dr. Aristóbulo Agudelo Vásquez
            </h1>
            <p className="text-lg mb-24 text-justify sm:text-3xl sm:px-8">
              Abogado litigante preparado para ayudarte con cualquier tema legal. Ubicado
              en la ciudad de Cali, pero con posibilidad de viajar a donde
              lo requieras.
            </p>
            
            <p className="text-lg sm:text-4xl">
              Correo electrónico:{" "}
              <a href="mailto:aristi0618@hotmail.com" className="text-blue-500">
                aristi0618@hotmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* Botón flotante de WhatsApp */}
      <button
        className="bg-green-500 hover:bg-green-600 sm:h-[4%] h-[5%] w-fit text-white font-bold py-2 px-4 rounded-full absolute bottom-5 right-5 sm:bottom-8 sm:right-8"
        onClick={redirectToWhatsApp}
      >
        WhatsApp
      </button>
    </div>
  );
};

export default App;
